import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import Swiper from "react-id-swiper"
// import { Swiper, SwiperSlide } from "swiper/react"
// import SwiperCore, { Navigation } from "swiper/core"

// SwiperCore.use([Navigation])
const SwiperItemsList = ({ title, titleClass, navClass, items }) => {
  const swiperRef = useRef(null)
  const [currentIndex, updateCurrentIndex] = useState(0)
  const [prevNav, setPrevNav] = useState(false)
  const [nextNav, setNextNav] = useState(true)

  const params = {
    spaceBetween: 10,
    watchSlidesVisibility: true,
    allowTouchMove: false,
    navigation: {
      nextEl: `.swiper-next-${navClass}`,
      prevEl: `.swiper-prev-${navClass}`,
    },
    on: {
      init: () => {
        window.dispatchEvent(new Event("resize"))
      },
      slideChange: e => {
        e.activeIndex > 0 ? setPrevNav(true) : setNextNav(true)
      },
      reachEnd: () => setNextNav(false),
      reachBeginning: () => setPrevNav(false),
      slideNextTransitionStart: () => setPrevNav(true),
      slidePrevTransitionStart: () => setNextNav(true),
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
        allowTouchMove: true,
      },
      0: {
        slidesPerView: 2,
        allowTouchMove: true,
      },
    },
  }

  const goNext = e => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = e => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  var itemsObjects = null
  if (items && items !== null) {
    let itemsList = JSON.parse(items)
    if (itemsList.length !== 0) {
      itemsObjects = Object.keys(itemsList).map(key => itemsList[key])
    }
  }
  return (
    <>
      {itemsObjects && (
        <div className="swiper-list-wrap">
          {title && <h2 className={`main-title ${titleClass}`}>{title}</h2>}
          <div className="swiper-list-inner">
            {itemsObjects !== null && itemsObjects.length > 0 ? (
              <>
                <Swiper {...params} ref={swiperRef}>
                  {itemsObjects.map((item, idx) => (
                    <div
                      className="swiper-slide"
                      key={`related-item-${idx}-${item.URLSegment}`}
                    >
                      <a href={item.Link} className="product-items">
                        <div
                          className={`product-items-thumb ${item.ImagePosition}`}
                        >
                          <img src={item.Image} alt=""></img>
                          <div className="grid-caption">
                            <h4>{item.Title}</h4>
                            <div className="grid-bottom">
                              <p className="variant-name">one</p>
                              <div className="d-flex mx-n2">
                                <div className="mx-2 item-feature">
                                  1
                                  <svg width="24" height="24">
                                    <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                                  </svg>
                                </div>
                                <div className="mx-2 item-feature">
                                  2
                                  <svg width="24" height="24">
                                    <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                                  </svg>
                                </div>
                                <div className="mx-2 item-feature">
                                  3
                                  <svg width="24" height="24">
                                    <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                                  </svg>
                                </div>
                                <div className="mx-2 item-feature">
                                  1
                                  <svg width="24" height="24">
                                    <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </Swiper>
                <div
                  className={`swiper-ct-prev swiper-prev-${navClass}${
                    prevNav ? "" : " swiper-button-disabled"
                  }`}
                  onClick={goPrev}
                ></div>
                <div
                  className={`swiper-ct-next swiper-next-${navClass}${
                    nextNav ? "" : " swiper-button-disabled"
                  }`}
                  onClick={goNext}
                ></div>
              </>
            ) : (
              <p>Have no item</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

SwiperItemsList.propTypes = {
  title: PropTypes.string,
  navClass: PropTypes.string.isRequired,
  items: PropTypes.string,
}

export default SwiperItemsList
